<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Revisión de solicitudes de vacaciones
            </div>

            <!-- FILTROS -->
            <v-expansion-panels v-model="activePanels">
                <v-expansion-panel style="background-color: #F5F5F5" @click.prevent="toogleFilters">
                    <v-expansion-panel-header>
                        <span class="header">Filtros</span>
                        <template v-slot:actions>
                            <v-btn icon @click.stop="toogleFilters">
                                <v-icon>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form :disabled="loading">
                            <v-row>
                                <!-- <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                    <v-text-field
                                        class="mb-n7" outlined dense
                                        type="number"
                                        v-model="filtros.Ano"
                                        label="Año"
                                        @change="onChangeYear">
                                    </v-text-field>
                                </v-col> -->
                                <v-col cols="12" sm="12" md="6" lg="6" xl="5">
                                    <DirFind class="mb-n7" ref="dirFind" 
                                        v-model="filtros.Dir" 
                                        label="Tienda" 
                                        multiple smallChips deletableChips
                                        @change="loadData">
                                    </DirFind>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" lg="6" xl="4">
                                    <PerFind class="mb-n7" ref="perFind" 
                                        v-model="filtros.Per" 
                                        :perCatIdAnyLevel="101" 
                                        label="Empleado" 
                                        multiple smallChips deletableChips
                                        @change="loadData">
                                    </PerFind>
                                </v-col>
                                <v-col cols="12" sm="6" md="5" lg="4" xl="3">
                                    <PerRespFind class="mb-n7" ref="respFind" 
                                        v-model="filtros.Resp" 
                                        :perCatIdAnyLevel="101" 
                                        label="Responsable" 
                                        respType="VAC"
                                        @change="loadData">
                                    </PerRespFind>
                                </v-col>
                                <v-col class="mb-n7" cols="6" sm="5" md="3" lg="3" xl="2">
                                    <DateFind label="Desde" v-model="filtros.FechaDesde" @change="loadData"></DateFind>
                                </v-col>
                                <v-col class="mb-n7" cols="6" sm="5" md="3" lg="3" xl="2">
                                    <DateFind label="Hasta" v-model="filtros.FechaHasta" @change="loadData"></DateFind>
                                </v-col>
                                <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                    <PeriodFind 
                                        ref="periodFind" 
                                        v-model="filtros.Period" 
                                        label="Periodo" 
                                        @change="onChangePeriod">
                                    </PeriodFind>
                                </v-col>
                                <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                    <PerCatFind class="mb-n7" ref="perCatFind" v-model="filtros.PerCat" label="Categoría" @change="loadData"></PerCatFind>
                                </v-col>
                                <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                    <v-text-field
                                        class="mb-n7"
                                        outlined dense
                                        type="number"
                                        v-model="filtros.PendientesDesde"
                                        label="Días pendientes desde"
                                        @change="onChangeDiasPendientes">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                    <v-text-field
                                        class="mb-n7"
                                        outlined dense
                                        type="number"
                                        v-model="filtros.PendientesHasta"
                                        label="Días pendientes hasta"
                                        @change="onChangeDiasPendientes">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="4" lg="4" xl="3">
                                    <v-select 
                                        dense outlined hide-details
                                        v-model="filtros.ModoDiasSolicitados"
                                        :items="modoDiasSolicitadosOptions"
                                        item-value="value"
                                        item-text="label"
                                        label="Empleados con días solicitados"
                                        :menu-props="{ offsetY: true }"
                                        @change="loadData">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="4" xl="3">
                                    <v-select 
                                        dense outlined hide-details
                                        v-model="filtros.ModoDiasConcedidos"
                                        :items="modoDiasConcedidosOptions"
                                        item-value="value"
                                        item-text="label"
                                        label="Empleados con días concedidos"
                                        :menu-props="{ offsetY: true }"
                                        @change="loadData">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="4" xl="3">
                                    <v-select 
                                        dense outlined hide-details
                                        v-model="filtros.ModoDiasDenegados"
                                        :items="modoDiasDenegadosOptions"
                                        item-value="value"
                                        item-text="label"
                                        label="Empleados con días denegados"
                                        :menu-props="{ offsetY: true }"
                                        @change="loadData">
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="4" lg="4" xl="3">
                                    <v-select 
                                        dense outlined hide-details
                                        v-model="filtros.ModoSemanaDiasSolicitados"
                                        :items="modoDiasSolicitadosOptions"
                                        item-value="value"
                                        item-text="label"
                                        label="Semanas con días solicitados"
                                        :menu-props="{ offsetY: true }"
                                        @change="getHeaders">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="4" xl="3">
                                    <v-select 
                                        dense outlined hide-details
                                        v-model="filtros.ModoSemanaDiasConcedidos"
                                        :items="modoDiasConcedidosOptions"
                                        item-value="value"
                                        item-text="label"
                                        label="Semanas con días concedidos"
                                        :menu-props="{ offsetY: true }"
                                        @change="getHeaders">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="4" xl="3">
                                    <v-select 
                                        dense outlined hide-details
                                        v-model="filtros.ModoSemanaDiasDenegados"
                                        :items="modoDiasDenegadosOptions"
                                        item-value="value"
                                        item-text="label"
                                        label="Semanas con días denegados"
                                        :menu-props="{ offsetY: true }"
                                        @change="getHeaders">
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                    <v-select 
                                        dense outlined hide-details
                                        v-model="filtros.TiendasMostrar"
                                        :items="tiendasMostrarOptions"
                                        item-value="value"
                                        item-text="label"
                                        label="Tiendas a mostrar"
                                        :menu-props="{ offsetY: true }"
                                        @change="loadData">
                                    </v-select>
                                </v-col>
                                <v-col v-if="empId == 8 || empId == 12" cols="12" sm="6" md="3" lg="3" xl="3">
                                    <v-select 
                                        dense outlined hide-details
                                        v-model="filtros.OpcNum"
                                        :items="opcNumOptions"
                                        item-value="value"
                                        item-text="label"
                                        label="Opción"
                                        :menu-props="{ offsetY: true }"
                                        @change="loadData">
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <v-row class="mt-2">
                <v-col cols="12">
                    <v-data-table
                        :headers="headers"
                        :items="dataRows"
                        :options.sync="options"
                        :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
                        :loading="loading"
                        style="overflow: scroll"
                        mobile-breakpoint="0"
                        class="elevation-1 mb-3 vac-table"
                        dense>
                        <template v-slot:item="{ item }">
                            <!-- <tr> -->
                            <tr v-for="scope in [{
                                id: item.PerId,
                                diasSol: getVacTotalDays(true, [statusRefs.solicitado.code, statusRefs.solicitadoProv.code, statusRefs.aceptadoProv.code, statusRefs.denegadoProv.code], item.PerId),
                                diasAcep: getVacTotalDays(true, [statusRefs.aceptado.code], item.PerId)
                            }]" :key="scope.id">
                                <td class="fixed-column tienda">{{item.Dir.DirNom}}</td>
                                <td class="fixed-column nombre">{{item.PerNom}}</td>
                                <!-- <td class="text-end">{{getDaysOfType(item.Vac, [statusRefs.solicitado.code, statusRefs.aceptadoProv.code, statusRefs.denegadoProv.code])}}</td>
                                <td class="text-end">{{getDaysOfType(item.Vac, [statusRefs.aceptado.code])}}</td>
                                <td class="text-end">
                                    {{
                                        30 - 
                                        (getDaysOfType(item.Vac, [statusRefs.solicitado.code, statusRefs.aceptadoProv.code, statusRefs.denegadoProv.code]) + getDaysOfType(item.Vac, [statusRefs.aceptado.code]))
                                    }}
                                </td> -->
                                <td class="text-end">{{ scope.diasSol }}</td>
                                <td class="text-end">{{ scope.diasAcep }}</td>
                                <td class="text-end">{{ 30 - (scope.diasSol + scope.diasAcep) }}</td>
                                <!-- Solicitudes por semana -->
                                <td class="week-data text-center" v-for="week in weekHeaders" :key="week.id">
                                    <!-- <template v-if="getDaysOfType(item.Vac, [statusRefs.solicitado.code], week) != 0 ||
                                        getDaysOfType(item.Vac, [statusRefs.aceptado.code, statusRefs.aceptadoProv.code], week) != 0 ||
                                        getDaysOfType(item.Vac, [statusRefs.denegado.code, statusRefs.denegadoProv.code], week) != 0">

                                        <label 
                                            :class="'py-1 px-2 mr-1 vac-data white--text ' + statusRefs.solicitado.color" 
                                            @click="onClickWeekData(item, week, statusRefs.solicitado.code)"
                                        >{{
                                            getDaysOfType(item.Vac, [statusRefs.solicitado.code], week)
                                        }}</label>

                                        <label 
                                            :class="'py-1 px-2 mr-1 vac-data white--text ' + statusRefs.aceptado.color" 
                                            @click="onClickWeekData(item, week, statusRefs.aceptado.code)"
                                        >{{
                                            getDaysOfType(item.Vac, [statusRefs.aceptado.code, statusRefs.aceptadoProv.code], week)
                                        }}</label>

                                        <label 
                                            :class="'py-1 px-2 mr-1 vac-data white--text ' + statusRefs.denegado.color" 
                                            @click="onClickWeekData(item, week, statusRefs.denegado.code)"
                                        >{{
                                            getDaysOfType(item.Vac, [statusRefs.denegado.code, statusRefs.denegadoProv.code], week)
                                        }}</label>

                                    </template> -->
                                    <div v-for="scopeWeek in [{
                                        id: week.id,
                                        diasSol: getVacTotalDays(false, [statusRefs.solicitado.code, statusRefs.solicitadoProv.code], item.PerId, week),
                                        diasAcep: getVacTotalDays(false, [statusRefs.aceptado.code, statusRefs.aceptadoProv.code], item.PerId, week),
                                        diasDen: getVacTotalDays(false, [statusRefs.denegado.code, statusRefs.denegadoProv.code], item.PerId, week)
                                    }]" :key="scopeWeek.id">
                                        <template v-if="scopeWeek.diasSol != 0 || scopeWeek.diasAcep != 0 || scopeWeek.diasDen != 0">

                                            <label 
                                                :class="'py-1 px-2 mr-1 vac-data white--text ' + statusRefs.solicitado.color" 
                                                @click="onClickWeekData(item, week, statusRefs.solicitado.code)"
                                            >{{
                                                scopeWeek.diasSol
                                            }}</label>

                                            <label 
                                                :class="'py-1 px-2 mr-1 vac-data white--text ' + statusRefs.aceptado.color" 
                                                @click="onClickWeekData(item, week, statusRefs.aceptado.code)"
                                            >{{
                                                scopeWeek.diasAcep
                                            }}</label>

                                            <label 
                                                :class="'py-1 px-2 mr-1 vac-data white--text ' + statusRefs.denegado.color" 
                                                @click="onClickWeekData(item, week, statusRefs.denegado.code)"
                                            >{{
                                                scopeWeek.diasDen
                                            }}</label>

                                        </template>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <!-- Totales -->
                        <template slot="body.append">
                            <!-- <tr class="total-row"> -->
                            <tr class="total-row" v-for="scope in [{
                                id: 'total',
                                diasSol: getVacTotalDays(true, [statusRefs.solicitado.code, statusRefs.solicitadoProv.code, statusRefs.aceptadoProv.code, statusRefs.denegadoProv.code]),
                                diasAcep: getVacTotalDays(true, [statusRefs.aceptado.code])
                            }]" :key="scope.id">
                                <th class="fixed-column tienda">Total</th>
                                <th class="fixed-column nombre"></th>
                                <!-- <th class="text-end">
                                    {{ getTotalDaysOfType([statusRefs.solicitado.code, statusRefs.aceptadoProv.code, statusRefs.denegadoProv.code]) }}
                                </th> 
                                <th class="text-end">
                                    {{ getTotalDaysOfType([statusRefs.aceptado.code]) }}
                                </th>
                                <th class="text-end">{{
                                    (30 * dataRows.length) - 
                                    (getTotalDaysOfType([statusRefs.solicitado.code, statusRefs.aceptadoProv.code, statusRefs.denegadoProv.code]) + 
                                    getTotalDaysOfType([statusRefs.aceptado.code]))
                                }}</th> -->
                                <th class="text-end">{{ scope.diasSol }}</th> 
                                <th class="text-end">{{ scope.diasAcep }}</th>
                                <th class="text-end">{{
                                    (30 * dataRows.length) - (scope.diasSol + scope.diasAcep)
                                }}</th>
                                <!-- Solicitudes totales por semana -->
                                <th class="week-data text-center" v-for="week in weekHeaders" :key="week.id">
                                    <!-- <label :class="'py-1 px-2 mr-1 white--text ' + statusRefs.solicitado.color">{{
                                        getTotalDaysOfType([statusRefs.solicitado.code], week)
                                    }}</label>
                                    <label :class="'py-1 px-2 mr-1 white--text ' + statusRefs.aceptado.color">{{
                                        getTotalDaysOfType([statusRefs.aceptado.code, statusRefs.aceptadoProv.code], week)
                                    }}</label>
                                    <label :class="'py-1 px-2 mr-1 white--text ' + statusRefs.denegado.color">{{
                                        getTotalDaysOfType([statusRefs.denegado.code, statusRefs.denegadoProv.code], week)
                                    }}</label> -->
                                    <label :class="'py-1 px-2 mr-1 white--text ' + statusRefs.solicitado.color">{{
                                        getVacTotalDays(false, [statusRefs.solicitado.code, statusRefs.solicitadoProv.code], null, week)
                                    }}</label>
                                    <label :class="'py-1 px-2 mr-1 white--text ' + statusRefs.aceptado.color">{{
                                        getVacTotalDays(false, [statusRefs.aceptado.code, statusRefs.aceptadoProv.code], null, week)
                                    }}</label>
                                    <label :class="'py-1 px-2 mr-1 white--text ' + statusRefs.denegado.color">{{
                                        getVacTotalDays(false, [statusRefs.denegado.code, statusRefs.denegadoProv.code], null, week)
                                    }}</label>
                                </th>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>

        <!-- GUARDAR / PUBLICAR / SALIR -->
        <v-footer app bottom fixed padless>
            <v-row class="mt-1 mb-2" justify="center">
                <v-btn
                    class="mt-1 mb-1 mr-2" color="primary"
                    :disabled="newVacStaVacList.length === 0"
                    :loading="loading"
                    @click="saveData">
                    Grabar cambios
                </v-btn>
                <v-btn
                    class="mt-1 mb-1 mr-2" color="primary"
                    :disabled="newVacStaVacList.length !== 0"
                    :loading="loading"
                    @click="publishData">
                    Publicar cambios
                </v-btn>
                <v-btn
                    class="mt-1 mb-1 mr-2"
                    style="width:100px"
                    @click="showConfirmExitDialog = true">
                    Salir
                </v-btn>
            </v-row>
        </v-footer>

        <!-- Modales -->
        <!-- Confirmar salida -->
        <v-dialog
            v-model="showConfirmExitDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    SALIR
                </v-card-title>

                <v-card-text class="mt-2">
                    <p>¿Estás seguro de que desea salir?</p>
                    <p>Se perderán todos los cambios no guardados.</p>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn to="/" color="primary">
                        Salir
                    </v-btn>
                    <v-btn class="ml-2" @click="showConfirmExitDialog = false">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- ACEPTAR / DENEGAR SOLICITUD -->
        <v-dialog
            v-model="showEditVacDialog"
            transition="dialog-bottom-transition"
            persistent
            width="750">
            <v-card>
                <v-card-title class="text-h5 grey lighten-4">
                    DETALLE
                </v-card-title>

                <v-card-text class="mt-2">
                    <p>Fecha de solicitud: 
                        <span v-if="editVacSta.FecSolMax > editVacSta.FecSolMin">
                            Entre {{formatDate(editVacSta.FecSolMin, true)}} y {{formatDate(editVacSta.FecSolMax, true)}}
                        </span>
                        <span v-else>
                            {{formatDate(editVacSta.FecSolMin, true)}}
                        </span>
                    </p>
                    <p>Empleado: {{editVacSta.PerNom}}</p>
                    <p>
                        Fechas: 
                        <span v-for="vac in editVacSta.Vac" :key="vac.VacId">
                            <template v-if="vac.VacFecDes != vac.VacFecHas">
                                {{formatDate(vac.VacFecDes)}} - {{formatDate(vac.VacFecHas)}}
                            </template>
                            <template v-else>
                                {{formatDate(vac.VacFecDes)}}
                            </template>
                            <template v-if="vac.VacId != editVacSta.Vac[editVacSta.Vac.length - 1].VacId"> |</template>
                        </span>
                    </p>

                    <v-radio-group hide-details v-model="editVacSta.VacStaId" row>
                        <v-radio label="Aceptar" :value="statusRefs.aceptadoProv.code"></v-radio>
                        <v-radio label="Denegar" :value="statusRefs.denegadoProv.code"></v-radio>
                    </v-radio-group>

                    <div v-if="editVacSta.VacStaId === statusRefs.denegadoProv.code" class="mt-3">
                        <VacStaDetFind 
                            ref="vacStaDetFind" 
                            v-model="editVacSta.Motivo" 
                            label="Motivo"
                            extraData
                            :vacStaId="statusRefs.denegadoProv.code">
                        </VacStaDetFind>
                        <div v-if="editVacSta.Motivo != null && editVacSta.Motivo.VacStaDetId === -1" class="mt-3">
                            <v-textarea outlined auto-grow hide-details
                                v-model="editVacSta.VacStaDetObs"
                                label="Motivo">
                            </v-textarea>
                        </div>
                    </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary"
                    :loading="loading"
                    :disabled="!validateVacSta()"
                    @click="onClickEditVacState">
                        Aceptar
                    </v-btn>
                    <v-btn color="error"
                    :loading="loading"
                    @click="onClickDeleteVac(editVacSta.Vac)">
                        Eliminar
                    </v-btn>
                    <v-btn class="ml-2" @click="onClickCancelEditVacState">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- DETALLE SOLICITUD -->
        <v-dialog
            v-model="showDetailVacDialog"
            transition="dialog-bottom-transition"
            persistent
            width="750"
            eager>
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    DETALLE
                </v-card-title>

                <v-card-text class="mt-2">
                    <template v-if="detailVacSta != null">
                        <v-tabs v-model="detailVacStaTab" background-color="transparent" show-arrows>
                            <v-tabs-slider color="blue"></v-tabs-slider>
                            <v-tab v-for="(vac, index) in detailVacSta.Vac" :key="vac.VacId" :href="'#tab-vacsta-' + (index+1)">Día {{index+1}}</v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="detailVacStaTab" touchless>
                            <v-tab-item  v-for="(vac, index) in detailVacSta.Vac" :key="vac.VacId" :value="'tab-vacsta-' + (index+1)">
                                <v-card flat>
                                    <p class="mt-2">Fecha de solicitud: {{formatDate(vac.VacStaFecSol, true)}}</p>
                                    <p>Empleado: {{vac.PerNom}}</p>
                                    <p>Fechas: 
                                        <span>
                                            <template v-if="vac.VacFecDes != vac.VacFecHas">
                                                {{formatDate(vac.VacFecDes)}} - {{formatDate(vac.VacFecHas)}}
                                            </template>
                                            <template v-else>
                                                {{formatDate(vac.VacFecDes)}}
                                            </template>
                                        </span>
                                    </p>
                                    <template v-if="detailVacSta.type === statusRefs.aceptado.code">
                                        <p>
                                            Fecha aceptación: {{formatDate(vac.VacSta.VacStaFec, true)}}
                                            <v-chip v-if="vac.VacSta.VacStaId === statusRefs.aceptadoProv.code"
                                                class="ml-1" small
                                                color="red" label outlined
                                            >Provisional</v-chip>
                                        </p>
                                        <p>Aceptado por: {{vac.VacSta.PerNom}}</p>
                                    </template>
                                    <template v-else>
                                        <p>
                                            Fecha denegación: {{formatDate(vac.VacSta.VacStaFec, true)}}
                                            <v-chip v-if="vac.VacSta.VacStaId === statusRefs.denegadoProv.code"
                                                class="ml-1" small
                                                color="red" label outlined
                                            >Provisional</v-chip>
                                        </p>
                                        <p>Denegado por: {{vac.VacSta.PerNom}}</p>
                                        <p style="white-space: pre-line">Motivo: {{vac.VacSta.VacStaDetObs}}</p>
                                    </template>
                                    
                                    <div class="mb-1">
                                        <v-btn
                                            class="mr-2"
                                            :loading="loading"
                                            @click="onClickChangeVacSta(vac, statusRefs.solicitadoProv.code)"
                                            >
                                            Solicitar
                                        </v-btn>
                                        <v-btn v-if="detailVacSta.type === statusRefs.denegado.code"
                                            class="mr-2"
                                            :loading="loading"
                                            @click="onClickChangeVacSta(vac, statusRefs.aceptadoProv.code)">
                                            Aceptar
                                        </v-btn>
                                        <v-btn v-if="detailVacSta.type === statusRefs.aceptado.code"
                                            class="mr-2"
                                            :loading="loading"
                                            @click="onClickChangeVacSta(vac, statusRefs.denegadoProv.code)">
                                            Denegar
                                        </v-btn>
                                        <v-btn color="error"
                                            class="mr-2"
                                            :loading="loading"
                                            @click="onClickDeleteVac([vac])">
                                            Eliminar
                                        </v-btn>
                                    </div>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </template>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="ml-2" @click="showDetailVacDialog = false; detailVacSta.Vac = [];">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- CAMBIAR ESTADO SOLICITUD -->
        <v-dialog
            v-model="showChangeVacStaDialog"
            transition="dialog-bottom-transition"
            persistent
            width="750"
            eager>
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                <template v-if="vacToChange.VacStaId === statusRefs.aceptadoProv.code">ACEPTAR SOLICITUD</template>
                <template v-else-if="vacToChange.VacStaId === statusRefs.denegadoProv.code">DENEGAR SOLICITUD</template>
                <template v-else-if="vacToChange.VacStaId === statusRefs.solicitadoProv.code">MARCAR COMO SOLICITADO</template>
                
                </v-card-title>

                <v-card-text class="mt-2">

                <v-row>
                    <v-col cols="12">
                        ¿Desea
                        <template v-if="vacToChange.VacStaId === statusRefs.aceptadoProv.code"> aceptar </template>
                        <template v-else-if="vacToChange.VacStaId === statusRefs.denegadoProv.code"> denegar </template>
                        <template v-else-if="vacToChange.VacStaId === statusRefs.solicitadoProv.code"> marcar como solicitado </template>
                        la solicitud seleccionada?
                    </v-col>
                </v-row>

                <v-row v-if="vacToChange.VacStaId === statusRefs.denegadoProv.code && 
                    vacToChange.OriginalVacStaId !== statusRefs.denegado.code && 
                    vacToChange.OriginalVacStaId !== statusRefs.denegadoProv.code &&
                    vacToChange.OriginalVacStaDefId !== statusRefs.denegado.code">
                    <v-col cols="12">
                       <VacStaDetFind 
                            ref="vacStaDetFind" 
                            v-model="vacToChangeMotivo.Motivo" 
                            label="Motivo"
                            extraData
                            :vacStaId="statusRefs.denegadoProv.code">
                        </VacStaDetFind>
                        <div v-if="vacToChangeMotivo.Motivo != null && vacToChangeMotivo.Motivo.VacStaDetId === -1" class="mt-3">
                            <v-textarea outlined auto-grow hide-details
                                v-model="vacToChangeMotivo.VacStaDetObs"
                                label="Motivo">
                            </v-textarea>
                        </div>
                    </v-col>
                </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    :loading="loading"
                    color="primary"
                    :disabled="!validateChangeVacSta()"
                    @click="onClickConfirmChangeVacSta"
                >
                    Aceptar
                </v-btn>
                <v-btn
                    @click="onClickCancelChangeVacSta"
                >
                    Cancelar
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- BORRAR VAC -->
        <v-dialog
            v-model="showDeleteVacDialog"
            transition="dialog-bottom-transition"
            persistent
            width="750"
            eager>
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                BORRAR SOLICITUDES
                </v-card-title>

                <v-card-text class="mt-2">
                ¿Desea borrar las solicitudes seleccionadas?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    :loading="loading"
                    color="primary"
                    @click="onClickConfirmDeleteVac"
                >
                    Aceptar
                </v-btn>
                <v-btn
                    @click="onBtnCancelDeleteVac"
                >
                    Cancelar
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import DateFind from "../components/DateFind.vue";
import DirFind from "../components/DirFind.vue";
import PeriodFind from "../components/PeriodFind.vue";
import PerFind from "../components/PerFind.vue";
import PerCatFind from "../components/PerCatFind.vue";
import PerRespFind from "../components/PerRespFind.vue";
import VacStaDetFind from "../components/VacStaDetFind.vue";
export default ({
    components: {
        DateFind, DirFind, PeriodFind, PerFind, PerCatFind, PerRespFind, VacStaDetFind
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz']),
        perTotalDays: function () {
            // Precalculo de los totales y estado actual de las solicitudes
            // para evitar el calculo en cada render de la pantalla.
            // Se recalculara al cambiar los datos que vienen del servidor o
            // al modificar una solicitud
            const perVacs = this.dataRows.map(m => {
                const vacs = m.Vac.map(m2 => {
                    let status = 0;
                    // Comprobamos si se ha editado
                    const editedVacSta = this.newVacStaVacList.find(f => f.VacId === m2.VacId)

                    if (editedVacSta != null) {
                        // Nuevo estado de solicitud
                        if (editedVacSta.VacStaId >= -1) {
                            status = editedVacSta.VacStaId;
                        } 
                        // Revertido a estado original definitivo
                        else {
                            status = m2.VacStaDef.VacStaId;
                        }
                    }
                    // Solicitud no editada 
                    else {
                        status = m2.VacSta.VacStaId;
                    }

                    const dateVacFecDes = new Date(m2.VacFecDes);
                    const dateVacFecHas = new Date(m2.VacFecHas);
                    const diferenciaDias = this.differenceInDays(dateVacFecHas, dateVacFecDes);
                    let diferenciaDiasVac = diferenciaDias;


                    // CALCULO DIAS FESTIVOS
                    const periodosFestivos = this.holidayPeriods
                        .filter(f => f.FecDes <= dateVacFecHas && f.FecHas >= dateVacFecDes);

                    periodosFestivos.forEach(item => {
                        const start = item.FecDes >= dateVacFecDes ?
                                item.FecDes : dateVacFecDes;
                        const end = item.FecHas <= dateVacFecHas ?
                            item.FecHas : dateVacFecHas;

                        const diferenciaFestivo = this.differenceInDays(end, start);
                        diferenciaDiasVac -= diferenciaFestivo;
                    });

                    return {
                        VacId: m2.VacId,
                        VacFecDes: dateVacFecDes,
                        VacFecHas: dateVacFecHas,
                        Days: diferenciaDias,
                        DaysWithHolidays: diferenciaDiasVac,
                        Status: status
                    };
                });
                return {
                    PerId: m.PerId,
                    Vac: vacs
                };
            });
            
            return perVacs;
        }
    },
    data () { 
        return {
            loading: false,
            showConfirmExitDialog: false,
            showEditVacDialog: false,
            showDetailVacDialog: false,
            showChangeVacStaDialog: false,
            showDeleteVacDialog: false,
            statusRefs: {
                solicitado: { code: 1, name: 'Solicitado', color: 'purple' },
                solicitadoProv: { code: 11, name: 'Solicitado provisional', color: 'purple' },
                aceptado: { code: 12, name: 'Aceptado', color: 'success' },
                aceptadoProv: { code: 2, name: 'Aceptado provisional', color: 'success' },
                denegado: { code: 13, name: 'Denegado', color: 'warning' },
                denegadoProv: { code: 3, name: 'Denegado provisional', color: 'warning' }
            },
            statusRelations: [
                { code: 1, provCode: 11, deleteCode: -11 },
                { code: 12, provCode: 2, deleteCode: -2 }, // Aceptado
                { code: 13, provCode: 3, deleteCode: -3 }  // Denegado
            ],
            opcNumOptions: [
                { label: 'PRIMERA OPCIÓN', value: 1 },
                { label: 'SEGUNDA OPCIÓN', value: 2 }
            ],
            modoDiasSolicitadosOptions:  [
                { label: 'TODOS', value: 0 },
                { label: 'CON DÍAS SOLICITADOS', value: 1 },
                { label: 'SIN DÍAS SOLICITADOS', value: 2 }
            ],
            modoDiasConcedidosOptions: [
                { label: 'TODOS', value: 0 },
                { label: 'CON DÍAS CONCEDIDOS', value: 1 },
                { label: 'SIN DÍAS CONCEDIDOS', value: 2 }
            ],
            modoDiasDenegadosOptions: [
                { label: 'TODOS', value: 0 },
                { label: 'CON DÍAS DENEGADOS', value: 1 },
                { label: 'SIN DÍAS DENEGADOS', value: 2 }
            ],
            tiendasMostrarOptions: [
                { label: 'TODAS', value: 0 },
                { label: 'NO FLOTANTES', value: 1 },
                { label: 'FLOTANTES', value: 2 }
            ],
            activePanels: 0,
            filtros: {
                Ano: null,
                Dir: [],
                Per: [],
                Resp: {},
                FechaDesde: null,
                FechaHasta: null,
                Period: null,
                OpcNum: 1,
                PerCat: null,
                ModoDiasSolicitados: 0,
                ModoDiasConcedidos: 0,
                ModoDiasDenegados: 0,
                ModoSemanaDiasSolicitados: 0,
                ModoSemanaDiasConcedidos: 0,
                ModoSemanaDiasDenegados: 0,
                PendientesDesde: -99,
                PendientesHasta: 99,
                TiendasMostrar: 0
            },
            holidayPeriods: [],
            // Tablas
            totalDataRows: 100,
            dataRows: [],
            options: {itemsPerPage: 15},
            baseHeaders: [
                { text: 'Tienda', width: 230, class: 'fixed-column tienda', sortable: false, value: 'Dir.DirNom' },
                { text: 'Nombre del empleado', width: 310, class: 'fixed-column nombre', sortable: false, value: 'PerNom' },
                { text: 'Solicitados', width: 92, sortable: false, value: 'DiasSol', align: 'end' },
                { text: 'Concedidos', width: 95, sortable: false, value: 'DiasCon', align: 'end' },
                { text: 'Pendientes', width: 92, sortable: false, value: 'DiasPen', align: 'end' },
            ],
            weekHeaders: [],
            headers: [],
            // dtOptionsLoaded: false,
            // Edición
            newVacStaVacList: [],
            detailVacSta: null,
            detailVacStaTab: 'tab-vacsta-1',
            editVacSta: {
                FecSolMin: null,
                FecSolMax: null,
                PerNom: '',
                VacStaId: null,
                Vac: [],
                VacStaDetObs: '',
                Motivo: null
            },
            vacToChange: {},
            vacToChangeMotivo: {},
            vacsToDelete: [] // Solicitudes seleccionadas para eliminar
        };
    },
    watch: {
      loading: {
        handler() {
          if (this.loading) {
            this.$refs.dirFind.$refs.DirFindCtrl.isMenuActive = false;
            this.$refs.perFind.$refs.PerFindCtrl.isMenuActive = false;
            this.$refs.respFind.$refs.PerRespFindCtrl.isMenuActive = false;
            this.$refs.periodFind.$refs.PeriodFindCtrl.isMenuActive = false;
            this.$refs.perCatFind.$refs.PerCatFindCtrl.isMenuActive = false;
          }
        },
        deep: true
      }
    },
    mounted() {
        this.headers = this.baseHeaders;

        const currentYear = new Date().getFullYear(); 
        this.filtros.Ano = currentYear;
        this.filtros.FechaDesde = currentYear + '-01-01';
        this.filtros.FechaHasta = currentYear + '-12-31';

        this.filtros.Resp = { PerId: this.perId, PerNom: this.$store.state.nombreUsuario };
        this.loadData();
    },
    methods: {
        getDataFromApi() {
            this.loading = true;

            const dirIds = this.filtros.Dir.map(m => m.DirId);
            const perIds = this.filtros.Per.map(m => m.PerId);

            return new Promise((resolve) => {
            const controllerParameters = {
                Action: 'GET_RESP_DATA_LIST',
                EmpId: this.empId,
                // Ano: this.filtros.Ano,
                FechaDesde: this.filtros.FechaDesde,
                FechaHasta: this.filtros.FechaHasta,
                DirIds: dirIds,
                PerIds: perIds,
                RespId: this.filtros.Resp == null ? null : this.filtros.Resp.PerId,
                PeriodId: this.filtros.Period != null ? this.filtros.Period.PeriodId : null,
                OpcNum: this.filtros.OpcNum,
                PerCatId: this.filtros.PerCat == null ? null : this.filtros.PerCat.PerCatId,
                ModoDiasSolicitados: this.filtros.ModoDiasSolicitados,
                ModoDiasConcedidos: this.filtros.ModoDiasConcedidos,
                ModoDiasDenegados: this.filtros.ModoDiasDenegados,
                PendientesDesde: this.filtros.PendientesDesde,
                PendientesHasta: this.filtros.PendientesHasta,
                TiendasMostrar: this.filtros.TiendasMostrar
            };
            const AuthToken = localStorage.getItem('token');
            axios({ method: "POST", "url": this.urlRaiz + "/api/vac", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
            .then(result => {
                const items = result.data.EntsList;
                const holidayPeriods = result.data.HolidayPeriods;

                setTimeout(() => {
                    this.loading = false;
                    resolve({
                        items,
                        holidayPeriods
                    })
                }, 1000)
            }).catch(error => {
                if (error != null) {
                    this.loading = false;
                    alert('Error al cargar las solicitudes.');
                }
            });
            })
        },
        postDataToApi() {
            this.loading = true;

            return new Promise((resolve) => {
            const controllerParameters = {
                Action: 'SAVE_LIST_RESP',
                EmpId: this.empId,
                PerId: this.perId,
                VacStaVacList: this.newVacStaVacList
            };       
            const AuthToken = localStorage.getItem('token');
            axios({ method: "POST", "url": this.urlRaiz + "/api/vac", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
            .then(result => {
                const success = result.data.Success;
                const message = result.data.Message;

                setTimeout(() => {
                    this.loading = false
                    resolve({
                        success,
                        message
                    })
                }, 1000)
            }).catch(error => {
                if (error != null) {
                    this.loading = false;
                    alert('Error al guardar las solicitudes.');
                }
            });
            })
        },
        publishDataToApi() {
            this.loading = true;

            return new Promise((resolve) => {
            const controllerParameters = {
                Action: 'PUBLISH_LIST_RESP',
                EmpId: this.empId,
                PerId: this.perId
            };       
            const AuthToken = localStorage.getItem('token');
            axios({ method: "POST", "url": this.urlRaiz + "/api/vac", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
            .then(result => {
                const success = result.data.Success;
                const message = result.data.Message;

                setTimeout(() => {
                    this.loading = false
                    resolve({
                        success,
                        message
                    })
                }, 1000)
            }).catch(error => {
                if (error != null) {
                    this.loading = false;
                    alert('Error al guardar las solicitudes.');
                }
            });
            })
        },
        loadData() {
            this.getDataFromApi()
            .then(data => {
                this.dataRows = data.items;
                // this.totalDataRows = this.dataRows.length;
                this.holidayPeriods = data.holidayPeriods.map(m => {
                    return {
                        FecDes: new Date(m.FecDes),
                        FecHas: new Date(m.FecHas)
                    };
                });
                this.mapData();
            });
        },
        mapData() {
            // Calculamos las semanas a mostrar
            this.getHeaders();
        },
        saveData() {
            if (this.newVacStaVacList.length == 0) { return; }
            this.postDataToApi()
            .then(data => {
                if (data.success == true) {
                    this.newVacStaVacList = [];
                    this.loadData();
                    alert('Las solicitudes se han guardado correctamente.');
                } else {
                    alert(data.message);
                }
            });
        },
        publishData() {
            if (this.newVacStaVacList.length > 0) { return; }
            this.publishDataToApi()
            .then(data => {
                if (data.success == true) {
                    this.loadData();
                    alert('Las solicitudes se han guardado correctamente.');
                } else {
                    alert(data.message);
                }
            });
        },
        // En type se tiene que pasar el tipo definitivo (no provisional)
        onClickWeekData(per, week, type) {
            if (this.loading) { return; }

            // Comprobamos las relaciones de estados
            let types = [type];
            const typeRelation = this.statusRelations.find(f => f.code == type);
            if (typeRelation != null) {
                types.push(typeRelation.provCode);
            }

            // Filtro por semana
            const weekVacs = per.Vac.filter(f => 
                new Date(f.VacFecDes) <= week.end && 
                new Date(f.VacFecHas) >= week.start
            );

            // Filtro por tipo
            let weekVacsOfType = weekVacs.filter(f => types.indexOf(f.VacSta.VacStaId) !== -1);

            // Solicitados
            if (type == this.statusRefs.solicitado.code) {
                // Comprobamos si hay días solicitados a modificar
                // Quitamos de la lista los que ya se han editado
                const editedVacIds = this.newVacStaVacList
                    .filter(f => f.VacStaId !== this.statusRefs.solicitadoProv.code)
                    .map(m => m.VacId);

                let vacsToShow = weekVacsOfType.filter(f => 
                    editedVacIds.indexOf(f.VacId) === -1
                );

                // Buscamos los dias que se han devuelto a solicitado
                const revisedDays = weekVacs.filter(f => 
                    this.newVacStaVacList.filter(f2 => 
                        f2.VacId == f.VacId && types.indexOf(f2.VacStaId) !== -1
                    ).length > 0
                );

                if (revisedDays.length > 0) {
                    vacsToShow = vacsToShow.concat(revisedDays);
                }

                if (vacsToShow.length == 0) { return; }

                // Ordenamos las fechas a mostrar
                vacsToShow = vacsToShow.sort((a, b) => new Date(a.VacFecDes) - new Date(b.VacFecDes));

                // Buscamos las fechas de solicitud máxima y mínima
                const fecSolList = vacsToShow.map(m => {
                    const d = new Date(m.VacStaFecSol);
                    d.setSeconds(0, 0);
                    return d;
                });
                const minDate = new Date(Math.min.apply(null, fecSolList));
                const maxDate = new Date(Math.max.apply(null, fecSolList));

                // Asignamos los datos para la edición
                this.editVacSta.FecSolMin = minDate;
                this.editVacSta.FecSolMax = maxDate;
                this.editVacSta.PerNom = per.PerNom;
                this.editVacSta.VacStaId = null;
                this.editVacSta.Vac = vacsToShow;
                // this.editVacSta.VacStaDetId = null;
                this.editVacSta.VacStaDetObs = '';
                this.Motivo = null;
                
                // Mostramos el dialogo de edicion
                this.showEditVacDialog = true;
            } 
            // Aceptados - Denegados
            else {
                // Filtramos las solicitudes que se han editado/borrado
                weekVacsOfType = weekVacsOfType.filter(f => 
                    this.newVacStaVacList.filter(f2 => 
                        f2.VacId == f.VacId && types.indexOf(f2.VacStaId) === -1
                    ).length == 0
                );

                // Mapeo de los datos a mostrar
                let daysToShow = weekVacsOfType.map(m => {
                    return {
                        VacId: m.VacId,
                        VacStaFecSol: m.VacStaFecSol,
                        PerId: per.PerId,
                        PerNom: per.PerNom,
                        VacFecDes: m.VacFecDes,
                        VacFecHas: m.VacFecHas,
                        VacSta: {
                            VacStaId: m.VacSta.VacStaId,
                            VacStaFec: m.VacSta.VacStaFec,
                            PerNom: m.VacSta.PerNom,
                            VacStaDetObs: m.VacSta.VacStaDetObs
                        }
                    };
                });

                // Buscamos los dias que se han editado
                const revisedDays = weekVacs.filter(f => 
                    // f.VacSta.VacStaId == this.statusRefs.solicitado.code &&
                    this.newVacStaVacList.filter(f2 => 
                        f2.VacId == f.VacId && types.indexOf(f2.VacStaId) !== -1
                    ).length > 0
                );

                if (revisedDays.length > 0) {
                    const now = new Date(); // Simulacion de la fecha de revision

                    // Unimos las listas
                    const daysToAdd = revisedDays.map(m => {
                        const newVacStaData = this.newVacStaVacList.find(f => f.VacId == m.VacId);
                        return {
                            VacId: m.VacId,
                            VacStaFecSol: m.VacStaFecSol,
                            PerId: per.PerId,
                            PerNom: per.PerNom,
                            VacFecDes: m.VacFecDes,
                            VacFecHas: m.VacFecHas,
                            VacSta: {
                                VacStaId: newVacStaData.VacStaId,
                                VacStaFec: now,
                                PerNom: this.$store.state.nombreUsuario,
                                VacStaDetObs: newVacStaData.VacStaDetObs
                            }
                        };
                    });
    
                    daysToShow = daysToShow.concat(daysToAdd);
                }

                // Buscamos los días de los que se ha eliminado su estado provisional
                // y buscamos su estado definitivo actual
                const reversedDays = weekVacs.filter(f => 
                    this.newVacStaVacList.filter(f2 => 
                        f2.VacId == f.VacId && f2.VacStaId < -1
                    ).length > 0
                    && f.VacStaDef.VacStaId === type
                );
                
                // Mapear de VacStaDef a daysToShow
                if (reversedDays.length > 0) {
                    const daysToAdd = reversedDays.map(m => {
                        return {
                            VacId: m.VacId,
                            VacStaFecSol: m.VacStaFecSol,
                            PerId: per.PerId,
                            PerNom: per.PerNom,
                            VacFecDes: m.VacFecDes,
                            VacFecHas: m.VacFecHas,
                            VacSta: {
                                VacStaId: m.VacStaDef.VacStaId,
                                VacStaFec: m.VacStaDef.VacStaFec,
                                PerNom: m.VacStaDef.PerNom,
                                VacStaDetObs: m.VacStaDef.VacStaDetObs
                            }
                        };
                    });
                    daysToShow = daysToShow.concat(daysToAdd);
                }
                
                if (daysToShow.length == 0) { return; }
                // Ordenamos las fechas a mostrar
                daysToShow = daysToShow.sort((a, b) => new Date(a.VacFecDes) - new Date(b.VacFecDes));

                // Mostramos el dialogo
                this.detailVacSta = {
                    type: type,
                    Vac: daysToShow
                };
                this.showDetailVacDialog = true;
            }
        },
        // onClickEditVacStateOLD() {
        //     if (!this.validateVacSta()) { return; }
        //     const newVacStaVacList = [];

        //     let VacStaDetId = null;
        //     let VacStaDetObs = '';
        //     // Comprobamos el motivo de denegación
        //     if (this.editVacSta.VacStaId == this.statusRefs.denegadoProv.code) {
        //         if (this.editVacSta.Motivo.VacStaDetId == -1) {
        //             VacStaDetObs = this.editVacSta.VacStaDetObs;
        //         } else {
        //             VacStaDetId = this.editVacSta.Motivo.VacStaDetId;
        //             VacStaDetObs = this.editVacSta.Motivo.VacStaDetObs;
        //         }
        //     }

        //     this.editVacSta.Vac.forEach(vac => {
        //         const newVacStaVac = {
        //             VacId: vac.VacId,
        //             VacStaId: this.editVacSta.VacStaId,
        //             VacStaDetId: VacStaDetId,
        //             VacStaDetObs: VacStaDetObs
        //         };
        //         newVacStaVacList.push(newVacStaVac);
        //     });
        //     // Añadimos las solicitudes modificadas a la lista
        //     this.newVacStaVacList = this.newVacStaVacList.concat(newVacStaVacList);

        //     // Cerramos el modal y reiniciamos los datos de edición
        //     this.showEditVacDialog = false;    
        //     this.editVacSta = {
        //         PerNom: '',
        //         VacStaId: null,
        //         Vac: [],
        //         VacStaDetObs: '',
        //         Motivo: null
        //     };
        // },
        onClickEditVacState() {
            if (!this.validateVacSta()) { return; }
            
            const vacs = this.editVacSta.Vac.map(m => {
                return {
                    VacId: m.VacId,
                    OriginalVacStaId: m.VacSta.VacStaId,
                    OriginalVacStaDefId: m.VacStaDef.VacStaId
                };
            });

            const vacStaVacData = {
                VacStaId: this.editVacSta.VacStaId,
                Motivo: this.editVacSta.Motivo,
                VacStaDetObs: this.editVacSta.VacStaDetObs
            };

            this.cambioEstado(vacs, vacStaVacData);

            // Cerramos el modal y reiniciamos los datos de edición
            this.showEditVacDialog = false;    
            this.editVacSta = {
                PerNom: '',
                VacStaId: null,
                Vac: [],
                VacStaDetObs: '',
                Motivo: null
            };
        },
        onClickCancelEditVacState() {
            // Cerramos el modal y reiniciamos los datos de edición
            this.showEditVacDialog = false;    
            this.editVacSta = {
                PerNom: '',
                VacStaId: null,
                Vac: [],
                VacStaDetObs: '',
                Motivo: null
            };
        },
        //#region Aceptar/rechazar una solicitud rechazada/aceptada
        // Mapea los datos a mostrar y abre el dialogo de cambio de estado
        onClickChangeVacSta(vac, newVacStaId) {
            // Comprobamos la solicitud original
            let originalVacSta;
            const originalPer = this.dataRows.find(f => f.PerId === vac.PerId);
            if (originalPer != null) {
                originalVacSta = originalPer.Vac.find(f => f.VacId === vac.VacId);
            }
            
            // Comprobamos si la solicitud original se ha modificado
            const isEdited = this.newVacStaVacList.findIndex(f => f.VacId === vac.VacId) != -1; // todos menos los eliminados
            
            // Mapeo de datos y mostramos el dialogo
            this.vacToChange = {
                VacId: vac.VacId,
                VacStaId: newVacStaId,
                OriginalVacStaId: originalVacSta != null ? originalVacSta.VacSta.VacStaId : null,
                OriginalVacStaDefId: originalVacSta != null ? originalVacSta.VacStaDef.VacStaId : null,
                IsEdited: isEdited
            };
            this.showChangeVacStaDialog = true;
        },

        // OLD - Confirma el cambio de estado
        // onClickConfirmChangeVacStaOLD() {
        //     // Eliminamos el cambio de estado si se ha modificado
        //     if (this.vacToChange.IsEdited) {
        //         this.newVacStaVacList = this.newVacStaVacList.filter(f => f.VacId !== this.vacToChange.VacId);
        //     }

        //     // Buscamos la relacción de estados del estado a asignar
        //     const currentStatusRelation = this.statusRelations.find(f => f.provCode === this.vacToChange.VacStaId);
        //     if (currentStatusRelation == null) { return; }
            
        //     // Si el estado original de la solitud no es el mismo que el que se va a asignar
        //     // añadimos la solicitud a la lista de modificados
        //     if (this.vacToChange.OriginalVacStaId !== currentStatusRelation.provCode &&
        //     this.vacToChange.OriginalVacStaDefId !== currentStatusRelation.code) 
        //     {
        //         let VacStaDetId = null;
        //         let VacStaDetObs = '';
        //         // Comprobamos el motivo de denegación
        //         if (this.vacToChange.VacStaId === this.statusRefs.denegadoProv.code) {
        //             if (this.vacToChangeMotivo.Motivo.VacStaDetId == -1) {
        //                 VacStaDetObs = this.vacToChangeMotivo.VacStaDetObs;
        //             } else {
        //                 VacStaDetId = this.vacToChangeMotivo.Motivo.VacStaDetId;
        //                 VacStaDetObs = this.vacToChangeMotivo.Motivo.VacStaDetObs;
        //             }
        //         }
                
        //         // Añadimos la solicitud
        //         const newVacStaVac = {
        //             VacId: this.vacToChange.VacId,
        //             VacStaId: this.vacToChange.VacStaId,
        //             VacStaDetId: VacStaDetId,
        //             VacStaDetObs: VacStaDetObs
        //         };
        //         this.newVacStaVacList.push(newVacStaVac);
        //     }
        //     // Si el estado definivo es el que vamos a asignar y el estado actual no es el mismo
        //     // marcamos el estado provisional para borrarlo
        //     else if (this.vacToChange.OriginalVacStaDefId === currentStatusRelation.code && 
        //     this.vacToChange.OriginalVacStaDefId !== this.vacToChange.OriginalVacStaId)
        //     {
        //         // Añadimos la solicitud
        //         const newVacStaVac = {
        //             VacId: this.vacToChange.VacId,
        //             VacStaId: currentStatusRelation.deleteCode, // Eliminar estado provisional
        //             VacStaDetId: null,
        //             VacStaDetObs: ''
        //         };
        //         this.newVacStaVacList.push(newVacStaVac);
        //     }

        //     // Cerrar dialogos y reseteo de variales
        //     this.showChangeVacStaDialog = false;
        //     this.showDetailVacDialog = false;
        //     this.vacToChange = {};
        //     this.vacToChangeMotivo = {};
        //     if (this.detailVacSta != null) { this.detailVacSta.Vac = []; }
        // },

        // Confirma el cambio de estado
        onClickConfirmChangeVacSta() {
            const vacs = [this.vacToChange];

            const vacStaVacData = {
                VacStaId: this.vacToChange.VacStaId,
                Motivo: this.vacToChangeMotivo.Motivo,
                VacStaDetObs: this.vacToChangeMotivo.VacStaDetObs
            };

            this.cambioEstado(vacs, vacStaVacData);
            
            // Cerrar dialogos y reseteo de variales
            this.showChangeVacStaDialog = false;
            this.showDetailVacDialog = false;
            this.vacToChange = {};
            this.vacToChangeMotivo = {};
            if (this.detailVacSta != null) { this.detailVacSta.Vac = []; }
        },
        // Cancela el cambio de estado. Reinicia variables y cierra el modal
        onClickCancelChangeVacSta() {
            this.vacToChange = {};
            this.vacToChangeMotivo = {};
            this.showChangeVacStaDialog = false;
        },
        //#endregion

        //#region Cambio de estado
        cambioEstado(vacs, vacStaVacData) {
            // Obtenemos las ids de las solicitudes a modificar
            const vacIds = vacs.map(m => m.VacId);

            // Eliminamos los cambios de estado si se han modificado
            this.newVacStaVacList = this.newVacStaVacList
                    .filter(f => vacIds.indexOf(f.VacId) === -1);

            // Buscamos la relacción de estados del estado a asignar
            const currentStatusRelation = this.statusRelations.find(f => f.provCode === vacStaVacData.VacStaId);
            if (currentStatusRelation == null) { return; }
            
            vacs.forEach(vac => {
                // Si el estado original de la solitud no es el mismo que el que se va a asignar
                // añadimos la solicitud a la lista de modificados
                if (vac.OriginalVacStaId !== currentStatusRelation.provCode &&
                vac.OriginalVacStaDefId !== currentStatusRelation.code) 
                {
                    let VacStaDetId = null;
                    let VacStaDetObs = '';
                    // Comprobamos el motivo de denegación
                    if (vacStaVacData.VacStaId === this.statusRefs.denegadoProv.code) {
                        if (vacStaVacData.Motivo.VacStaDetId == -1) {
                            VacStaDetObs = vacStaVacData.VacStaDetObs;
                        } else {
                            VacStaDetId = vacStaVacData.Motivo.VacStaDetId;
                            VacStaDetObs = vacStaVacData.Motivo.VacStaDetObs;
                        }
                    }
                    
                    // Añadimos la solicitud
                    const newVacStaVac = {
                        VacId: vac.VacId,
                        VacStaId: vacStaVacData.VacStaId,
                        VacStaDetId: VacStaDetId,
                        VacStaDetObs: VacStaDetObs
                    };
                    this.newVacStaVacList.push(newVacStaVac);
                }
                // Si el estado definivo es el que vamos a asignar y el estado actual no es el mismo
                // marcamos el estado provisional para borrarlo
                else if (vac.OriginalVacStaDefId === currentStatusRelation.code && 
                vac.OriginalVacStaDefId !== vac.OriginalVacStaId)
                {
                    // Añadimos la solicitud
                    const newVacStaVac = {
                        VacId: vac.VacId,
                        VacStaId: currentStatusRelation.deleteCode, // Eliminar estado provisional
                        VacStaDetId: null,
                        VacStaDetObs: ''
                    };
                    this.newVacStaVacList.push(newVacStaVac);
                }
            });
        },
        //#endregion


        //#region Eliminar solicitud
        // Asignación de las solicitudes a eliminar
        onClickDeleteVac(vacs) {
            this.vacsToDelete = vacs;
            this.showDeleteVacDialog = true;
        },
        // Confirma la eliminación de la solicitud
        onClickConfirmDeleteVac() {
            this.vacsToDelete.forEach(vac => {
                // Eliminamos el cambio de estado si se ha modificado
                this.newVacStaVacList = this.newVacStaVacList.filter(f => f.VacId !== vac.VacId);
                
                const deletedVacStaVac = {
                    VacId: vac.VacId,
                    VacStaId: -1, // Eliminar
                    VacStaDetId: null,
                    VacStaDetObs: ''
                };
                this.newVacStaVacList.push(deletedVacStaVac);
            });

            // Cerrar dialogos y reseteo de variales
            this.showDeleteVacDialog = false;
            this.showEditVacDialog = false;
            this.showDetailVacDialog = false;
            if (this.detailVacSta != null) { this.detailVacSta.Vac = []; }
        },
        // Cancelar la eliminación de la solicitud
        onBtnCancelDeleteVac() {
            this.vacsToDelete = [];
            this.showDeleteVacDialog = false;
        },
        //#endregion
        //#region Calculo de totales BACKUP
        // getDaysOfType(vacList, codes, week = null) {
        //     let numberOfDays = 0;
        //     let vacsOfType = vacList.filter(f => codes.indexOf(f.VacSta.VacStaId) !== -1);
        //     // Filtro por semana
        //     if (week != null) {
        //         vacsOfType = vacsOfType.filter(f => new Date(f.VacFecDes) <= week.end && new Date(f.VacFecHas) >= week.start);
        //     }

        //     vacsOfType.forEach(vac => {
        //         // Si tiene un nuevo estado no hacemos nada
        //         const index = this.newVacStaVacList.map(m => m.VacId).indexOf(vac.VacId);
        //         if (index != -1) { return; }

        //         // Calculamos el número de días
        //         numberOfDays += this.differenceInDays(new Date(vac.VacFecHas), new Date(vac.VacFecDes));
        //     });

        //     // Comprobamos las solicitudes editadas con el estado buscado
        //     let editedVacs = vacList.filter(f => codes.indexOf(f.VacSta.VacStaId) === -1);
        //     if (week != null) {
        //         editedVacs = editedVacs.filter(f => new Date(f.VacFecDes) <= week.end && new Date(f.VacFecHas) >= week.start);
        //     }
        //     editedVacs.forEach(vac => {
        //         // Días editados
        //         const index = this.newVacStaVacList
        //             .filter(f => codes.indexOf(f.VacStaId) !== -1)
        //             .map(m => m.VacId).indexOf(vac.VacId);
        //         if (index != -1) {
        //             numberOfDays += this.differenceInDays(new Date(vac.VacFecHas), new Date(vac.VacFecDes));
        //         }
        //         // Días revertidos al original definitivo
        //         else {
        //             const reversedDays = this.newVacStaVacList.filter(f => 
        //                 f.VacStaId < -1 && vac.VacId === f.VacId && codes.indexOf(vac.VacStaDef.VacStaId) !== -1
        //             ).length;
        //             if (reversedDays != 0) {
        //                 numberOfDays += this.differenceInDays(new Date(vac.VacFecHas), new Date(vac.VacFecDes));
        //             }
        //         }
        //     });

        //     return numberOfDays;
        // },
        // getTotalDaysOfType(codes, week = null) {
        //     let vacList = [];
        //     this.dataRows.forEach(row => {
        //         vacList = vacList.concat(row.Vac);
        //     });
        //     return this.getDaysOfType(vacList, codes, week);
        // },
        //#endregion
        getVacTotalDays(countHolidays, codes, perId = null, week = null) {
            let perVacs;
            // Filtro por persona
            if (perId != null) {
                perVacs = this.perTotalDays.find(f => f.PerId == perId);
            }
            else {
                let vacs = [];
                this.perTotalDays.forEach(item => {
                    vacs = vacs.concat(item.Vac);
                });
                perVacs = {
                    PerId: 0,
                    Vac: vacs
                };
            }
            if (perVacs == null) { return 0; }
            let vacsToShow = perVacs.Vac;

            // Filtro por semana
            if (week != null) {
                vacsToShow = vacsToShow.filter(f => f.VacFecDes <= week.end && f.VacFecHas >= week.start);
            }
            if (vacsToShow.length === 0) { return 0; }

            // Filtro por estado
            vacsToShow = vacsToShow.filter(f => codes.indexOf(f.Status) !== -1);
            
            // Calculo de dias totales
            const daysArray = vacsToShow.map(m => countHolidays ? m.DaysWithHolidays : m.Days);
            const totalDays = daysArray.reduce((a,b) => a + b, 0);
            return totalDays;
        },
        getHeaders() {
            let idCounter = 0;
            const weekHeaders = [];

            const firstDay = new Date (this.filtros.FechaDesde);
            const lastDay = new Date (this.filtros.FechaHasta);

            // Calculamos el día de la semana del primer día
            let dayOfWeek = firstDay.getDay() - 1;
            // Si es domingo, serán 6 días
            if (dayOfWeek == -1) dayOfWeek = 6;

            // Calculamos el último día a mostrar
            let lastDayDayOfWeek = lastDay.getDay() - 1;
            // Si es domingo, serán 6 días
            if (lastDayDayOfWeek == -1) lastDayDayOfWeek = 6;
            const lastSunday = new Date(new Date(lastDay).setDate(lastDay.getDate() + (6 - lastDayDayOfWeek)));

            for (let currentDay = firstDay; currentDay <= lastSunday; currentDay = new Date(currentDay.setDate(currentDay.getDate() + 7))) 
            {    
                const weekMonday = new Date(new Date(currentDay).setDate(currentDay.getDate() - dayOfWeek));
                const weekSunday = new Date(new Date(currentDay).setDate(currentDay.getDate() + (6 - dayOfWeek)));
                weekMonday.setHours(0);
                weekSunday.setHours(0);

                let weekClass = '';
                if (this.filtros.Period != null) {
                    // Comprobamos si la semana esta en el rango del periodo elegido
                    const isOnPeriod = this.filtros.Period.PeriodFec.filter(f => 
                        weekMonday <= new Date(f.FecHas) && weekSunday >= new Date(f.FecDes)
                    ).length;

                    if (isOnPeriod > 0) {
                        weekClass = 'yellow lighten-5';
                    }
                }

                const week = this.getWeekNumber(currentDay);
                const currentCounter = idCounter++;

                const weekHeader = { 
                    id: currentCounter,
                    week: week, 
                    start: weekMonday, 
                    end: weekSunday, 
                    text: this.formatDate(weekMonday) + ' - ' +  this.formatDate(weekSunday) + ' Semana ' + week, 
                    width: 171, //115, 
                    class: weekClass,
                    sortable: false, 
                    value: 'semana-' + week
                };

                // Comprobamos los filtros de semanas
                let addWeek = true;
                if (this.filtros.ModoSemanaDiasSolicitados > 0 || this.filtros.ModoSemanaDiasConcedidos > 0 || this.filtros.ModoSemanaDiasDenegados > 0) {
                    let filterWeekSol = false;
                    if (this.filtros.ModoSemanaDiasSolicitados > 0) {
                        const diasSol = this.getVacTotalDays(false, [this.statusRefs.solicitado.code], null, weekHeader);
                        if (this.filtros.ModoSemanaDiasSolicitados === 1 && diasSol > 0) {
                            filterWeekSol = true;
                        }
                        else if (this.filtros.ModoSemanaDiasSolicitados === 2 && diasSol === 0) {
                            filterWeekSol = true;
                        }
                    }
                    
                    let filterWeekAccepted = false;
                    if (this.filtros.ModoSemanaDiasConcedidos > 0) {
                        const diasAcep = this.getVacTotalDays(false, [this.statusRefs.aceptado.code, this.statusRefs.aceptadoProv.code], null, weekHeader);
                        if (this.filtros.ModoSemanaDiasConcedidos === 1 && diasAcep > 0) {
                            filterWeekAccepted = true;
                        }
                        else if (this.filtros.ModoSemanaDiasConcedidos === 2 && diasAcep === 0) {
                            filterWeekAccepted = true;
                        }
                    }

                    let filterWeekDenied = false;
                    if (this.filtros.ModoSemanaDiasDenegados > 0) {
                        const diasDen = this.getVacTotalDays(false, [this.statusRefs.denegado.code, this.statusRefs.denegadoProv.code], null, weekHeader);
                        if (this.filtros.ModoSemanaDiasDenegados === 1 && diasDen > 0) {
                            filterWeekDenied = true;
                        }
                        else if (this.filtros.ModoSemanaDiasDenegados === 2 && diasDen === 0) {
                            filterWeekDenied = true;
                        }
                    }

                    addWeek = filterWeekSol || filterWeekAccepted || filterWeekDenied;
                }

                if (addWeek) { weekHeaders.push(weekHeader); }
            }

            this.weekHeaders = weekHeaders;
            this.headers = this.baseHeaders.concat(weekHeaders);
        },
        getWeekNumber(date, year = null) {
            if (year == null) { year = date.getFullYear(); }
            const oneJan = new Date(year + '-01-01T00:00:00');
            let oneJanDayOfWeek = oneJan.getDay();
            if (oneJanDayOfWeek == 0) oneJanDayOfWeek = 7;

            // Obviamos el cambio de hora
            const timezoneDifference = date.getTimezoneOffset() - oneJan.getTimezoneOffset();
            if (timezoneDifference != 0) {
                oneJan.setMinutes(oneJan.getMinutes() + timezoneDifference);
            }

            const numberOfDays = Math.floor((date - oneJan) / (24 * 60 * 60 * 1000));
            const result = Math.ceil((oneJanDayOfWeek + numberOfDays) / 7);
            return result;
        },
        differenceInDays(dateStart, dateEnd) {
            const diffInMs = dateStart - dateEnd;
            const diffInDays = Math.floor(diffInMs / (24 * 60 * 60 * 1000));
            // Incluimos el dia de comienzo
            return diffInDays + 1;
        },
        // onChangeYear() {
        //     // Si no se ha especificado ningún año usaremos el actual
        //     if (this.filtros.Ano == null || this.filtros.Ano == '') {
        //         this.filtros.Ano = new Date().getFullYear();
        //     }
        //     this.loadData();
        // },
        onChangePeriod() {
            // Asignamos los datos del periodo a los filtros
            if (this.filtros.Period != null) {
                this.filtros.Ano = new Date(this.filtros.Period.PeriodFec[0].FecDes).getFullYear();

                var minDate = new Date(Math.min.apply(null, this.filtros.Period.PeriodFec.map(m => new Date(m.FecDes))));
                var maxDate = new Date(Math.max.apply(null, this.filtros.Period.PeriodFec.map(m => new Date(m.FecHas))));

                this.filtros.FechaDesde = this.getDateString(minDate);
                this.filtros.FechaHasta = this.getDateString(maxDate);
            } else {
                this.filtros.Ano = new Date().getFullYear();
            }

            this.loadData();
        },
        onChangeDiasPendientes() {
            // Asigna el valor de los días si tiene un valor no válido
            this.filtros.PendientesDesde = this.fixDiasPendientes(this.filtros.PendientesDesde, -99);
            this.filtros.PendientesHasta = this.fixDiasPendientes(this.filtros.PendientesHasta, 99);
            this.loadData();
        },
        fixDiasPendientes(diasPendientes, diasPorDefecto = 0) {
            if (diasPendientes == null || diasPendientes === '') {
                return diasPorDefecto;
            }
            return Number(diasPendientes);
        },
        validateVacSta() {
            // Si no se ha aceptado o denegado
            if (this.editVacSta.VacStaId == null) { return false; }
            if (this.editVacSta.VacStaId === this.statusRefs.denegadoProv.code) {
                // Si no se ha indicado motivo de la denegación
                if (this.editVacSta.Motivo == null) { return false; }
                // Si no se ha indicado un motivo en la denegación manual
                if (this.editVacSta.Motivo.VacStaDetId === -1 && this.editVacSta.VacStaDetObs === '') { return false; }
            }
            return true;
        },
        validateChangeVacSta() {
            // Si el estado original es denegado no es necesario hacer la validación
            if (this.vacToChange.OriginalVacStaId === this.statusRefs.denegado.code) { return true; }
            if (this.vacToChange.OriginalVacStaId === this.statusRefs.denegadoProv.code) { return true; }
            if (this.vacToChange.OriginalVacStaDefId === this.statusRefs.denegado.code) { return true; }
            // Si el estado a asignar es denegado
            if (this.vacToChange.VacStaId === this.statusRefs.denegadoProv.code) {
                // Si no se ha indicado motivo de la denegación
                if (this.vacToChangeMotivo.Motivo == null) { return false; }
                // Si no se ha indicado un motivo en la denegación manual
                if (this.vacToChangeMotivo.Motivo.VacStaDetId === -1 && this.vacToChangeMotivo.VacStaDetObs == null && this.vacToChangeMotivo.VacStaDetObs === '') { 
                    return false;
                }
            } 
            return true;
        },
        toogleFilters() {
            this.activePanels = this.activePanels == null ? 0 : undefined;
        },
        formatDate (value, addTime = false) {
            const date = new Date(value);
            let dateString = String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0')  + '/' + date.getFullYear();
            if (addTime) {
                dateString += ' ' + String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0');
            }
            return dateString;
        },
        getDateString (value) {
            const date = new Date(value);
            return date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0')  + '-' + String(date.getDate()).padStart(2, '0');
        }
    }
});
</script>

<style>
    .vac-table td.week-data:hover {
        background-color: #AECAFF;
    }
    .vac-table td.week-data label.vac-data:hover {
        cursor: pointer;
    }
    .vac-table th.week-data {
        font-size: 10pt !important;
    }

    @media (min-width: 959px)  {
        .vac-table .fixed-column {
            position: sticky;
        }
        .vac-table tr td.fixed-column {
            background-color: white;
        }
        .vac-table tr:hover td.fixed-column {
            background-color: #EEEEEE;
        }
        .vac-table tr th.fixed-column {
            background-color: white;
        }
        .vac-table tr.total-row th.fixed-column {
            background-color: #424242;
        }
        .vac-table tr.total-row:hover th.fixed-column {
            background-color: #161616;
        }
        .vac-table .fixed-column.tienda {
            left: 0px;
        }
        .vac-table .fixed-column.nombre {
            left: 230px;
        }
    }


    .vac-table tr.total-row {
        background-color: #424242;
        color: white;
    }
    .vac-table tr.total-row:hover {
        background-color: #161616 !important;
    }
</style>
