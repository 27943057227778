<template>
    <v-select
      v-model="value"
      cache-items
      dense
      outlined
      hide-details
      :small-chips="smallChips"
      :deletable-chips="deletableChips"
      :hide-selected="multiple"
      :items="itemsList"
      :search-input.sync="searchItem"
      open-on-clear
      item-value="VacStaDetId"
      item-text="VacStaDetNom"
      no-data-text="Elige un motivo"
      :label="label"
      :readonly="readonly"
      return-object
      ref="VacStaDetFindCtrl"
      :multiple="multiple"
      :clearable="!readonly"
      :menu-props="{ 'open-on-click': true, offsetY: true }"
      @click="clickEvt"
      @input="inputEvt"
      @change="changeEvt"
      @blur="blurEvt"
    ></v-select>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";
  export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data: () => ({
      itemsList: [],
      searchItem: null
    }),
    props: {
      value: { default: null },
      label: { type: String, default: '' },
      vacStaId: { type: Number, default: null },
      extraData: { type: Boolean, default: false },
      extraValue: { type: Number, default: -1 },
      extraLabel: { type: String, default: 'REDACCIÓN MANUAL' },
      multiple: { type: Boolean, default: false },
      readonly: { type: Boolean, default: false },
      smallChips: { type: Boolean, default: false },
      deletableChips: { type: Boolean, default: false }
    },
    methods: {
      loadItems() {
        var controllerParameters = {   
          Action: 'GET_DATA_LIST',
          VacStaId: this.vacStaId
        };

        var AuthToken = localStorage.getItem('token');  
        axios({ method: "POST", "url": this.urlRaiz + "/api/vacstadet", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
        .then(result => {
          this.itemsList = [];
          result.data.EntsList.forEach(element => {
            var itemObject = { 
              VacStaDetId: element.VacStaDetId, 
              VacStaDetNom: element.VacStaDetNom,
              VacStaDetObs: element.VacStaDetObs
            };
            this.itemsList.push(itemObject);
          });

          if(this.extraData) {
            const redaccionManual = {
              VacStaDetId: this.extraValue,
              VacStaDetNom: this.extraLabel,
              VacStaDetObs: this.extraLabel
            }
            this.itemsList.push(redaccionManual);
          }
        });
      },
      clickEvt() {
        this.$emit('click');
      },
      inputEvt() {
        this.$emit('input', this.value);
      },
      changeEvt() {
        this.$emit('change', this.value);
      },
      blurEvt() {
        this.$emit('blur', this.value);
      }
    },
    mounted() {
      this.itemsList = [];
      this.loadItems();
    },
  }
</script>